<template>
    <div class="contact-info-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <!-- <div class="col-lg-4 col-md-6">
                    <div class="contact-info-box">
                        <div class="back-icon">
                            <i class='bx bx-map'></i>
                        </div>
                        <div class="icon">
                            <i class='bx bx-map'></i>
                        </div>
                        <h3>Our Address</h3>
                        <p>175 5th Ave, New York, NY 10010, United States</p>
                    </div>
                </div> -->

                <div class="col-lg-4 col-md-6">
                    <div class="contact-info-box">
                        <div class="back-icon">
                            <i class='bx bx-phone-call'></i>
                        </div>
                        <div class="icon">
                            <i class='bx bx-phone-call'></i>
                        </div>
                        <h3>Contact</h3>
                        <!-- <p>Mobile: <a href="tel:+44457895789">(+44) - 45789 - 5789</a></p> -->
                        <p>E-mail: <a href="mailto:public@laoyixing.io">public@laoyixing.io</a></p>
                    </div>
                </div>

                <!-- <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div class="contact-info-box">
                        <div class="back-icon">
                            <i class='bx bx-time-five'></i>
                        </div>
                        <div class="icon">
                            <i class='bx bx-time-five'></i>
                        </div>
                        <h3>Hours of Operation</h3>
                        <p>Monday - Friday: 09:00 - 20:00</p>
                        <p>Sunday & Saturday: 10:30 - 22:00</p>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>   

<script>

export default {
    name: 'ContactInfo'
}
</script>