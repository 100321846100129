<template>
    <div>
        <Navbar />
        <MainBanner />
        <WeDifferentFrom />
        <DriveDigitalRevolution />
        <WeOfferProfessional />
        <TheDataScience />
        <!-- <DataSciencePharmaceutical /> -->
        <!-- <OurDataScientist /> -->
        <!-- <WhatOurClientsSaying /> -->
        <!-- <Partner /> -->
        <!-- <LatestValuableInsights /> -->
        <!-- <WeLikeToStart /> -->
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import MainBanner from '../DataScienceMlCompany/MainBanner'
import WeDifferentFrom from '../DataScienceMlCompany/WeDifferentFrom'
import DriveDigitalRevolution from '../DataScienceMlCompany/DriveDigitalRevolution'
import WeOfferProfessional from '../DataScienceMlCompany/WeOfferProfessional'
import TheDataScience from '../DataScienceMlCompany/TheDataScience'
// import DataSciencePharmaceutical from '../Common/DataSciencePharmaceutical'
// import OurDataScientist from '../Common/OurDataScientist'
// import WhatOurClientsSaying from '../Common/WhatOurClientsSaying'
// import Partner from '../Common/Partner'
// import LatestValuableInsights from '../Common/LatestValuableInsights'
// import WeLikeToStart from '../Common/WeLikeToStart'
import Footer from '../Layout/Footer'

export default {
    name: 'DataScienceMlCompanyPage',
    components: {
        Navbar,
        MainBanner,
        WeDifferentFrom,
        DriveDigitalRevolution,
        WeOfferProfessional,
        TheDataScience,
        // DataSciencePharmaceutical,
        // OurDataScientist,
        // WhatOurClientsSaying,
        // Partner,
        // LatestValuableInsights,
        // WeLikeToStart,
        Footer,
    }
}
</script>
