<template>
    <div class="process-area ptb-100 bg-fafafb">
        <div class="container">
            <div class="section-title">
                <span class="sub-title"><img src="../../assets/images/star-icon.png" alt="image"> How It's Work</span>
                <h2>Our Data Analytics Process</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
            </div>

            <div class="row align-items-center m-0">
                <div class="col-lg-6 col-md-12 p-0">
                    <div class="process-image">
                        <img src="../../assets/images/process/process7.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 p-0">
                    <div class="process-content">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="single-box-item">
                                    <div class="d-flex align-items-center">
                                        <div class="image">
                                            <img src="../../assets/images/process/process-small1.png" alt="image">
                                        </div>
                                        <h3>Frame the Problem</h3>
                                        <div class="number">1</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="single-box-item">
                                    <div class="d-flex align-items-center">
                                        <div class="image">
                                            <img src="../../assets/images/process/process-small1.png" alt="image">
                                        </div>
                                        <h3>Collect the Raw Data</h3>
                                        <div class="number">2</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="single-box-item">
                                    <div class="d-flex align-items-center">
                                        <div class="image">
                                            <img src="../../assets/images/process/process-small1.png" alt="image">
                                        </div>
                                        <h3>Process the Data</h3>
                                        <div class="number">3</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="single-box-item">
                                    <div class="d-flex align-items-center">
                                        <div class="image">
                                            <img src="../../assets/images/process/process-small4.png" alt="image">
                                        </div>
                                        <h3>Explore the Data</h3>
                                        <div class="number">4</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="single-box-item">
                                    <div class="d-flex align-items-center">
                                        <div class="image">
                                            <img src="../../assets/images/process/process-small5.png" alt="image">
                                        </div>
                                        <h3>Perform Analysis</h3>
                                        <div class="number">5</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="single-box-item">
                                    <div class="d-flex align-items-center">
                                        <div class="image">
                                            <img src="../../assets/images/process/process-small6.png" alt="image">
                                        </div>
                                        <h3>Communicate Results</h3>
                                        <div class="number">6</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'OurDataAnalytics'
}
</script>