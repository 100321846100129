<template>
    <footer class="footer-area bg-color">
        <div class="container">
            <div class="row">
                <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <router-link to="/" class="logo">
                            <img src="../../assets/images/logo.png" alt="logo">
                        </router-link>
                        <p>Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                </div> -->
                <!-- <div class="col-lg-2 col-md-6 col-sm-6">
                    <div class="single-footer-widget pl-5">
                        <h3>Explore</h3>
                        <ul class="footer-links-list">
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/about-us-one">About</router-link></li>
                            <li><router-link to="/case-studies-two">Case Studies</router-link></li>
                            <li><router-link to="/blog-one">Our Blog</router-link></li>
                            <li><router-link to="/contact">Contact</router-link></li>
                        </ul>
                    </div>
                </div> -->
                <!-- <div class="col-lg-2 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Resources</h3>
                        <ul class="footer-links-list">
                            <li><router-link to="/team-one">Our Scientists</router-link></li>
                            <li><router-link to="/services-one">Our Services</router-link></li>
                            <li><router-link to="/testimonials">Testimonials</router-link></li>
                            <li><router-link to="/services-details-one">SaaS Solutions</router-link></li>
                            <li><router-link to="/services-details-two">eCommerce</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Address</h3>
                        <ul class="footer-contact-info">
                            <li><i class='bx bx-map'></i>175 5th Ave, New York, NY 10010, United States</li>
                            <li><i class='bx bx-phone-call'></i><a href="tel:+44587154756">+1 (123) 456 7890</a></li>
                            <li><i class='bx bx-envelope'></i><a href="mailto:hello@laoyixing.com">hello@laoyixing.com</a></li>
                            <li><i class='bx bxs-inbox'></i><a href="tel:+557854578964">+55 785 4578964</a></li>
                        </ul>
                    </div>
                </div> -->
            </div>
            <div class="footer-bottom-area">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <p><i class='bx bx-copyright'></i>{{currentYear}} 
                            <!-- <strong>laoyixing</strong> is Proudly Powered by <a target="_blank" href="https://envytheme.com/">EnvyTheme</a> -->
                        </p>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <ul>
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/contact">Contact</router-link></li>
                        </ul>
                    </div>

                    <!-- <div class="col-lg-6 col-md-6">
                        <ul>
                            <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                            <li><router-link to="/terms-of-service">Terms & Conditions</router-link></li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="footer-map"><img src="../../assets/images/footer-map.png" alt="image"></div>
    </footer>
</template>

<script>

export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>