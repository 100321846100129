<template>
    <div class="services-area ptb-100 bg-e3fbff">
        <div class="container">
            <div class="section-title">
                <span class="sub-title"><img src="../../assets/images/star-icon.png" alt="image"> Our Services</span>
                <h2>We Offer Professional Solutions For Business</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-item">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon1.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-two">Customer Analytics</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna doloresssit amet aliqua.</p>
                        <router-link to="/services-details-two" class="default-btn"><i class="flaticon-right"></i>Read More<span></span></router-link>
                        <div class="shape1"><img src="../../assets/images/services/services-shape1.png" alt="image"></div>
                        <div class="shape2"><img src="../../assets/images/services/services-shape2.png" alt="image"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-item">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon2.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-two">Risk Analytics</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna doloresssit amet aliqua.</p>
                        <router-link to="/services-details-two" class="default-btn"><i class="flaticon-right"></i>Read More<span></span></router-link>
                        <div class="shape1"><img src="../../assets/images/services/services-shape1.png" alt="image"></div>
                        <div class="shape2"><img src="../../assets/images/services/services-shape2.png" alt="image"></div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-item">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon3.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-two">Social Media Analytics</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna doloresssit amet aliqua.</p>
                        <router-link to="/services-details-two" class="default-btn"><i class="flaticon-right"></i>Read More<span></span></router-link>
                        <div class="shape1"><img src="../../assets/images/services/services-shape1.png" alt="image"></div>
                        <div class="shape2"><img src="../../assets/images/services/services-shape2.png" alt="image"></div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-item">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon4.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-two">Real Time Analytics</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna doloresssit amet aliqua.</p>
                        <router-link to="/services-details-two" class="default-btn"><i class="flaticon-right"></i>Read More<span></span></router-link>
                        <div class="shape1"><img src="../../assets/images/services/services-shape1.png" alt="image"></div>
                        <div class="shape2"><img src="../../assets/images/services/services-shape2.png" alt="image"></div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-item">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon5.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-two">Big Data Analytics</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna doloresssit amet aliqua.</p>
                        <router-link to="/services-details-two" class="default-btn"><i class="flaticon-right"></i>Read More<span></span></router-link>
                        <div class="shape1"><img src="../../assets/images/services/services-shape1.png" alt="image"></div>
                        <div class="shape2"><img src="../../assets/images/services/services-shape2.png" alt="image"></div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-item">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon6.png" alt="image">
                        </div>
                        <h3>
                            <router-link to="/services-details-two">Business Analytics</router-link>
                        </h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna doloresssit amet aliqua.</p>

                        <router-link to="/services-details-two" class="default-btn">
                            <i class="flaticon-right"></i>
                            Read More<span></span>
                        </router-link>

                        <div class="shape1">
                            <img src="../../assets/images/services/services-shape1.png" alt="image">
                        </div>
                        <div class="shape2">
                            <img src="../../assets/images/services/services-shape2.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="load-more-btn text-center">
                        <router-link to="/services-five" class="default-btn"><i class="flaticon-refresh"></i>Load More<span></span></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'WeOfferProfessionalSolutions'
}
</script>