<template>
    <div class="about-area pb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-image">
                        <img src="../../assets/images/about/about1.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <div class="content">
                            <span class="sub-title"><img src="../../assets/images/star-icon.png" alt="image"> About Us</span>
                            <h2>Drive Digital Revolution Through Data Science</h2>
                            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p> -->
                            <!-- <ul class="features-list">
                                <li><span>
                                    <div class="icon">
                                        <img src="../../assets/images/icon1.png" alt="image">
                                    </div>
                                    <h3>10 Years</h3>
                                    <p>On the market</p>
                                </span></li>

                                <li><span>
                                    <div class="icon">
                                        <img src="../../assets/images/icon2.png" alt="image">
                                    </div>
                                    <h3>45+</h3>
                                    <p>Team members</p>
                                </span></li>

                                <li><span>
                                    <div class="icon">
                                        <img src="../../assets/images/icon3.png" alt="image">
                                    </div>
                                    <h3>100%</h3>
                                    <p>Satisfaction rate</p>
                                </span></li>

                                <li><span>
                                    <div class="icon">
                                        <img src="../../assets/images/icon4.png" alt="image">
                                    </div>
                                    <h3>80%</h3>
                                    <p>Senior scientist</p>
                                </span></li>
                            </ul> -->
                            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p> -->
                            <!-- <router-link to="/about-us-one" class="default-btn"><i class="flaticon-right"></i>More About Us<span></span></router-link> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="circle-shape1"><img src="../../assets/images/shape/circle-shape1.png" alt="image"></div>
    </div>
</template>

<script>

export default {
    name: 'DriveDigitalRevolution'
}
</script>