<template>
    <div>
        <Navbar />
        <MainBanner />
        <FeaturedServices />
        <BusinessAnalytics />
        <DataScience />
        <Funfacts />
        <WeOfferProfessionalSolutions />
        <OurDataAnalytics />
        <MeetOurData />
        <WhatOurClientsSaying />
        <Partner />
        <WeLikeToStart />
        <LatestValuableInsights />
        <Footer class="bg-white" />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import MainBanner from '../DataAnalyticsAlStartup/MainBanner'
import FeaturedServices from '../DataAnalyticsAlStartup/FeaturedServices'
import BusinessAnalytics from '../Common/BusinessAnalytics'
import DataScience from '../Common/DataScience'
import Funfacts from '../Common/Funfacts'
import WeOfferProfessionalSolutions from '../DataAnalyticsAlStartup/WeOfferProfessionalSolutions'
import OurDataAnalytics from '../DataAnalyticsAlStartup/OurDataAnalytics'
import MeetOurData from '../Common/MeetOurData'
import WhatOurClientsSaying from '../DataAnalyticsAlStartup/WhatOurClientsSaying'
import Partner from '../DataAnalyticsAlStartup/Partner'
import WeLikeToStart from '../DataAnalyticsAlStartup/WeLikeToStart'
import LatestValuableInsights from '../Common/LatestValuableInsights'
import Footer from '../Layout/Footer'

export default {
    name: 'DataAnalyticsAlStartupPage',
    components: {
        Navbar,
        MainBanner,
        FeaturedServices,
        BusinessAnalytics,
        DataScience,
        Funfacts,
        WeOfferProfessionalSolutions,
        OurDataAnalytics,
        MeetOurData,
        WhatOurClientsSaying,
        Partner,
        WeLikeToStart,
        LatestValuableInsights,
        Footer,
    }
}
</script>
